<template>
  <a-modal :title="formTitle" :visible="open" width="50%" @cancel="cancel" @ok="submitForm">
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="密码" prop="passwd" >
        <a-input v-model="form.password" placeholder="请输入密码" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

        <script>
        import { resetPwd } from '@/api/biz/user'

        export default {
          name: 'ResetPwdModalForm',
          data() {
            return {
              formTitle: '重置密码',
              open: false,
              form: {
                password: '',
                id: ''
              },
              rules: {
                password: [
                  { required: true, message: '密码', trigger: 'blur' }
                ]
              }
            }
          },
          methods: {
            openModal(id) {
              debugger
              this.form.id = id
              this.open = true
            },
            onClose () {
              this.open = false
            },
            // 取消按钮
            cancel () {
              this.open = false
              this.reset()
            },
            reset() {
              this.form.password = null
            },
            submitForm() {
              this.$refs.form.validate(valid => {
                if (valid) {
                  resetPwd({
                    id: this.form.id,
                    password: this.form.password
                  }).then(res => {
                    if (res.code == 20001) {
                      this.open = false
                      this.$emit('ok')
                    } else {
                      this.$message.info(res.msg)
                    }
                  })
                } else {
                  return false
                }
              })
            }
          }
        }
        </script>

        <style scoped>

        </style>
